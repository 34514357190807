import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate, useParams } from "react-router-dom"
import Swal from 'sweetalert2';
import { useEffect } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


const ChallanForm = (prop) => {
    prop.setShow(true);
    const [key, setKey] = useState('personal');
    const propId = useParams().propId;
    const userToken = localStorage.getItem("userToken");
    const navigate = useNavigate();
    const [vendors, setVendors] = useState([]);
    const [taxType, setTaxType] = useState('igst');
    const [newProduct, setNewProduct] = useState({ name: '', hsn: '', qty: 0, price: 0 });
    const [selectedVendor, setselectedVendor] = useState({});
    const [formData, setFormData] = useState({
        customAddress: "",
        vendorIds: [],
        customName: "",
        customPhone: null,
        gstin: "",
        challanStatus: "draft",
        productDesc: [],
        state: "",
        poNo: "",
        stateCode: "",
        transMode: "",
        vehNo: "",
        place: "",
        customfax: "",
        terms: [],
        delName: "",
        delComm: "",
        delDate: null,
        delSign: "",
        recName: "",
        recComm: "",
        recDate: null,
        recSign: "",
        logoURL: "",
        challanType: "Original",
        recAdd: "",
        recPhone: "",
        recGst: "",
        recState: "",
        recStateCode: "",
    });


    const loadVendors = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_HOST}customersmine`,
            headers: {
                'Authorization': `Bearer ${userToken}`
            }
        };

        axios.request(config)
            .then((response) => {
                setVendors(response.data.customers);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        loadVendors();
    }, []);

    const handleAddProduct = () => {
        const { name, hsn, qty, price } = newProduct;
        const updatedProducts = [...formData.productDesc, { name, hsn, qty, price }];
        setFormData({
            ...formData,
            productDesc: updatedProducts,
        });
        setNewProduct({ name: '', hsn: 0, qty: 0, price: 0 });
    };

    const handleDeleteProduct = (index) => {
        const updatedProducts = formData.productDesc.filter((product, i) => i !== index);
        setFormData({ productDesc: updatedProducts });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleInputChange2 = (e) => {
        const { name, value } = e.target;
        setNewProduct((prevProduct) => ({ ...prevProduct, [name]: value }));
    };


    const handleSubmit = async (e) => {



        e.preventDefault();
        let data = JSON.stringify(formData);


        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_HOST}challan`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userToken}`
            },
            data: data
        }
        axios.request(config)
            .then((response) => {
                if (response?.data.status) {
                    navigate("/challans");
                    Swal.fire({
                        icon: "success",
                        title: "Challan Created Successfully",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                }

            })
            .catch((error) => {
                console.log(error);
                Swal.fire({
                    icon: "warning",
                    title: error.response?.data?.message,
                    showConfirmButton: false,
                    timer: 1500,
                });
            });

    };

    const handleVendorSelected = (s) => {
        setFormData({
            ...formData,
            customPhone: s?.phone,
            customName: s?.orgName,
            vendorIds: s?._id,
            orgName: s?.orgName,
            customAddress: s?.address

        })
    }


    return (
        <div className="container-right">
            <div className='container mt-5 mb-5'>
                <h2 className='font-weight-bolder mt-4 mb-4'>  New Delivery Challan</h2>
                <Form onSubmit={handleSubmit}>
                    <div className='create-invoice'>
                        <div className='label-input'>
                            <label>Organisation Name*</label>
                            <Form.Control
                                className='border mb-3'
                                as="select"
                                placeholder="Vendor Name"
                                name="vendorIds"
                                value={selectedVendor.customName}  // Use an empty string as the default value
                                onChange={(e) => {
                                    const selectedVendorId = e.target.value ? JSON.parse(e.target.value)._id : '';
                                    setselectedVendor(e.target.value ? JSON.parse(e.target.value) : null);
                                    handleVendorSelected(JSON.parse(e.target.value));

                                }}
                            >
                                <option></option>

                                {vendors.map((v) => (
                                    <option key={v._id} value={JSON.stringify(v)}>{v.orgName}</option>
                                ))}

                            </Form.Control>



                        </div>
                    </div>

                    <div className='customer-invoice-tabs'>

                        <Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            className="mb-3"
                        >
                            <Tab eventKey="personal" title="Item Table">
                                <div className='biiling-inshights product'>
                                    <Form.Group controlId="productDesc">

                                        <div className='mb-4'>
                                            <div className="row">
                                                <div className="col-sm product">
                                                    <label>
                                                        Product Name:
                                                        <input type="text" className="form-control" name="name" value={newProduct.name} onChange={handleInputChange2} />
                                                    </label>
                                                </div>
                                                <div className="col-sm product">
                                                    <label>
                                                 HSN:
                               
                                                        <input type="text" className="form-control" name="hsn" value={newProduct.hsn} onChange={handleInputChange2} />
                                                    </label>
                                                </div>
                                                <div className="col-sm product">
                                                    <label>
                                                        qty:
                                                        <input type="number" className="form-control" name="qty" value={newProduct.qty} onChange={handleInputChange2} />
                                                    </label>
                                                </div>
                                                <div className="col-sm product">
                                                    <label>
                                                        Price:
                                                        <input type="number" className="form-control" name="price" value={newProduct.price} onChange={handleInputChange2} />
                                                    </label>
                                                </div>
                                                <div className="col-sm-auto align-self-end">
                                                    <button type='button' className="btn btn-primary" id="add" onClick={handleAddProduct}>+</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='added-items'>
                                            <h6>Added Items</h6>
                                            <div className='mb-4'>
                                                <div className="row">
                                                    <div className="col-sm added">
                                                        {formData?.productDesc?.map((product, index) => (
                                                            <>
                                                                <label>
                                                                    Product Name:
                                                                    <input type="text" className="form-control" value={product.name} disabled />
                                                                </label>
                                                                <label>
                                                                    HSN Code:
                                                                    <input type="text" className="form-control" value={product.hsn} disabled />
                                                                </label>

                                                                <label>
                                                                    Qty:
                                                                    <input type="number" className="form-control" value={product.qty} disabled />
                                                                </label>

                                                                <label>
                                                                    Price:
                                                                    <input type="number" className="form-control" value={product.price} disabled />
                                                                </label>

                                                                <button type='button' id="add" className="btn btn-danger btn-sm ml-2" onClick={() => handleDeleteProduct(index)}>
                                                                    -
                                                                </button>
                                                                <br />
                                                            </>

                                                        ))}
                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                    </Form.Group>
                                </div>
                            </Tab>


                            <Tab eventKey="others" title="Delivery">
                                <div className='biiling-inshights product'>

                                    <Form.Group controlId="delName">
                                        <Form.Label>Delivery By Name</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            type="text"
                                            placeholder="Enter Delivery By Name"
                                            name="delName"
                                            value={formData.delName}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                
                                    <Form.Group controlId="delComm">
                                        <Form.Label>Delivery Comment</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            type="text"
                                            placeholder="Enter Delivery Comment"
                                            name="delComm"
                                            value={formData.delComm}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="delDate">
                                        <Form.Label>Delivery Date</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            type="date"
                                            placeholder="Enter Delivery Date"
                                            name="delDate"
                                            value={formData.delDate}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                </div>

                            </Tab>

                            <Tab eventKey="receive" title="Receiving">
                                <div className='biiling-inshights product'>

                                    <Form.Group controlId="recName">
                                        <Form.Label>Receiver Name</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            type="text"
                                            placeholder="Enter Receiver Name"
                                            name="recName"
                                            value={formData.recName}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                
                                    <Form.Group controlId="recAdd">
                                        <Form.Label>Receiver Address</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            type="text"
                                            placeholder="Enter Receiver Address"
                                            name="recAdd"
                                            value={formData.recAdd}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="recPhone">
                                        <Form.Label>Receiver Phone</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            type="number"
                                            placeholder="Enter Receiver Phone"
                                            name="recPhone"
                                            value={formData.recPhone}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="recGst">
                                        <Form.Label>Receiver GST</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            type="text"
                                            placeholder="Enter Receiver GST"
                                            name="recGst"
                                            value={formData.recGst}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="recState">
                                        <Form.Label>Receiver State</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            type="text"
                                            placeholder="Enter Receiver State"
                                            name="recState"
                                            value={formData.recState}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="recStateCode">
                                        <Form.Label>Receiver State Code</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            type="number"
                                            placeholder="Enter Receiver State"
                                            name="recStateCode"
                                            value={formData.recStateCode}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>

                         
                                </div>

                            </Tab>


                            <Tab eventKey="invoicing" title="Challan Details">
                                <div className='biiling-inshights product'>

                                    <Form.Group controlId="challanType">
                                        <Form.Label>Challan Type</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            as="select"

                                            name="challanType"
                                            value={formData.challanType}
                                            onChange={handleInputChange}
                                        >
                                            <option value={'Original'}>Original</option>
                                            <option value={'Duplicate'}>Duplicate</option>
                                            <option value={'Triplicate'}>Triplicate</option>

                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Group controlId="state">
                                        <Form.Label>State</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            type="text"
                                            placeholder="Enter State"
                                            name="state"
                                            value={formData.state}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="stateCode">
                                        <Form.Label>State Code</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            type="text"
                                            placeholder="Enter State Code"
                                            name="stateCode"
                                            value={formData.stateCode}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                    
                                    <Form.Group controlId="poNo">
                                        <Form.Label>PO No.</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            type="text"
                                            placeholder="Enter PO No."
                                            name="poNo"
                                            value={formData.poNo}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                    
                                </div>

                            </Tab>

                            <Tab eventKey="transactions" title="Transport Details">
                                <div className='biiling-inshights product'>
                                <Form.Group controlId="terms">
                                        <Form.Label>Terms</Form.Label>
                                        <textarea
                                           id="textArea"
                                            className='border mb-3'
                                            as="textarea"
                                            rows={5}
                                            placeholder="Enter Terms"
                                            name="terms"
                                            value={formData.terms}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>


                                    <Form.Group controlId="transMode">
                                        <Form.Label>Transport Mode</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            type="text"
                                            placeholder="Enter Transport Mode"
                                            name="transMode"
                                            value={formData.transMode}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="place">
                                        <Form.Label>Place</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            type="text"
                                            placeholder="Enter Place"
                                            name="place"
                                            value={formData.place}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="vehNo">
                                        <Form.Label>Vehicle No.</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            type="text"
                                            placeholder="Enter Vehicle No."
                                            name="vehNo"
                                            value={formData.vehNo}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                </div>

                            </Tab>


                        </Tabs>
                    </div>

                    <div className='customer-invoice-tabs'>
                        <Button variant="primary" id="draft" onClick={() => setFormData({ ...formData, challanStatus: "draft" })} type="submit">
                            Draft
                        </Button>

                        <Button className='m-5' id="final" onClick={() => setFormData({ ...formData, challanStatus: "complete" })} variant="primary" type="submit">
                            Save
                        </Button></div>


                </Form>



            </div>
        </div>
    );
};

export default ChallanForm;
