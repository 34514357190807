import React, { useState,useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Login from "./screen/authentication/Login";
import Signup from "./screen/authentication/SignUp";
import CreateInvoice from "./screen/Components/Invoice/CreateInvoice";
import CreateProposal from "./screen/Components/Proposal/CreateProposal";
import CreatePurchaseOrder from "./screen/Components/PurchaseOrder/CreatePurchaseOrder";
import InvoiceList from "./screen/Components/Invoice/InvoiceList";
import PeopleList from "./screen/Components/Customers/PeopleList";
import PurchaseOrderList from "./screen/Components/PurchaseOrder/PurchaseOrderList";
import ProposalList from "./screen/Components/Proposal/ProposalList";
import "../src/assets/css/style.css";
import Settings from "./screen/Components/Profile/Settings";
import InvoiceDetails from "./screen/Components/Invoice/InvoiceDetails";
import axios from "axios";
import PoDetails from "./screen/Components/PurchaseOrder/PoDetails";
import ProposalDetails from "./screen/Components/Proposal/ProposalDetails";
import Sidebar from "./screen/CommonScreens/Sidebar";
import CreateCustomer from "./screen/Components/Customers/Create";
import Dashboard from "./screen/Components/Dashboard";
import TaxInvoice from "./screen/Components/Templates/TaxIvoice";
import CreateOutside from "./screen/Components/Customers/CreateOutside";
import EditInvoice from "./screen/Components/Invoice/EditInvoice";
import EditPo from "./screen/Components/PurchaseOrder/EditPo";
import EditProposal from "./screen/Components/Proposal/EditProposal";
import EditCustomer from "./screen/Components/Customers/EditCustomer";
import ChallanList from "./screen/Components/Challan/ChallanList";
import ChallanDetails from "./screen/Components/Challan/ChallanDetails";
import EditChallan from "./screen/Components/Challan/EditChallan";
import ChallanForm from "./screen/Components/Challan/CreateChallan";

const App = () => {
  
  const [show, setShow] = useState();
  
  const userToken = localStorage.getItem("userToken");
  const [user, setUser] = useState({})

  const loadData = () => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_HOST}user`,
      headers: {
        'Authorization': `Bearer ${userToken}`
      }
    };

    axios.request(config)
      .then((response) => {
        setUser(response.data.data);
       
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    loadData();
  }, []);

    

  return (
    <>
   
      <Routes>
        <Route path="/" element={<> <Login setShow={setShow} /></>} />
        <Route path="/signup" element={<> <Signup setShow={setShow} /></>} />
        <Route path="/peoples" element={<> <Sidebar /> <PeopleList setShow={setShow} /></>} />
        <Route path="/invoices" element={<> <Sidebar /> <InvoiceList user={user}  setShow={setShow} /></>} />
        <Route path="/proposals" element={<> <Sidebar /> <ProposalList user={user}  setShow={setShow} /></>} />
        <Route path="/purchaseorders" element={<> <Sidebar /> <PurchaseOrderList user={user} setShow={setShow} /></>} />
        <Route path="/create-invoice/:propId" element={<> <Sidebar /> <CreateInvoice setShow={setShow} /></>} />
        <Route path="/create-proposal" element={<> <Sidebar /> <CreateProposal setShow={setShow} /></>} />
        <Route path="/create-purchaseorder" element={<> <Sidebar /> <CreatePurchaseOrder setShow={setShow} /></>} />
        <Route path="/invoice/:invoiceId/view" element={<InvoiceDetails/>} />
        <Route path="/purchaseorder/:poId/view" element={<PoDetails/>} />
        <Route path="/proposal/:proposalId/view" element={<ProposalDetails/>} />
        <Route path="/settings" element={<> <Sidebar /> <Settings setShow={setShow} /></>} />
        <Route path="/create-customer" element={<> <Sidebar /> <CreateCustomer setShow={setShow} /></>} />
        <Route path="/vendor-form/:formId/:owner" element={<><CreateOutside setShow={setShow} /></>} />
        <Route path="/dashboard" element={<> <Sidebar /> <Dashboard setShow={setShow} /></>} />
        <Route path="/tax" element={<>  <TaxInvoice setShow={setShow} /></>} />
        <Route path="/invoice/edit/:id" element={<> <Sidebar /> <EditInvoice setShow={setShow} /></>} />
        <Route path="/proposal/edit/:id" element={<> <Sidebar /> <EditProposal setShow={setShow} /></>} />
        <Route path="/po/edit/:id" element={<> <Sidebar /> <EditPo setShow={setShow} /></>} />
        <Route path="/customer/edit/:id" element={<> <Sidebar /> <EditCustomer setShow={setShow} /></>} />
        <Route path="/challans" element={<> <Sidebar /> <ChallanList user={user}  setShow={setShow} /></>} />
        <Route path="/create-challan" element={<> <Sidebar /> <ChallanForm setShow={setShow} /></>} />
        <Route path="/challan/:challanId/view" element={<ChallanDetails/>} />
        <Route path="/challan/edit/:id" element={<> <Sidebar /> <EditChallan setShow={setShow} /></>} />
        
      </Routes>
    </>
  );
};

export default App;
