import React, { useEffect, useState } from 'react';
import { BsThreeDots } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { CiShare1 } from "react-icons/ci";
import { ShimmerTable } from 'react-shimmer-effects';

const PeopleList = (prop) => {
  useEffect(() => {
    prop.setShow(true);
  }, []);

  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const userToken = localStorage.getItem("userToken");
  const [showVendorDetail, setShowVendorDetail] = useState(false);
  const [dashboardData, setDashboardData] = useState({});
  const [filter, setFilter] = useState('');

  const loadVendors = () => {
    setLoading(true); // Set loading to true before fetching
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_HOST}customersmine`,
      headers: {
        'Authorization': `Bearer ${userToken}`
      }
    };

    axios.request(config)
      .then((response) => {
        setVendors(response.data.customers);
        setLoading(false); // Set loading to false after fetching
      })
      .catch((error) => {
        console.log(error);
        setLoading(false); // Set loading to false on error
      });
  }

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_HOST}user`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setDashboardData(data.data);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      }
    };

    fetchDashboardData();
  }, []);

  const navigate = useNavigate();
  const handleAddPeople = () => {
    navigate("/create-customer");
  }

  const handleCreate = () => {
    navigate(`/vendor-form/${dashboardData._id}/${dashboardData.fullName}`)
  }

  useEffect(() => {
    loadVendors();
  }, []);

  const handleDelete = (vendorId) => {
    let config = {
      method: 'delete',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_HOST}customer-delete/${vendorId}`,
      headers: {
        'Authorization': `Bearer ${userToken}`
      }
    };

    axios.request(config)
      .then((response) => {
        if (response.data.status) {
          window.location.reload();
          Swal.fire({
            icon: "success",
            title: "Vendor Deleted Successfully",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          icon: "warning",
          title: error.response.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
      });
  }

  const handleFilter = async () => {
    try {
      const config = {
        headers: {
          'Authorization': `Bearer ${userToken}`
        }
      };

      const response = await axios.get(`${process.env.REACT_APP_HOST}filteredcustomers?filter=${filter}`, config);
      setVendors(response.data.customers);
    } catch (error) {
      setVendors([]);
    }
  };

  return (
    <div className="container-right">
      <div className='container mt-5 mt-0 d-flex flex-column'>
        <div className='filter'>
          <select onChange={(e) => setFilter(e.target.value)}>
            <option value={'todays'}>Today's</option>
            <option value={'Last3months'}>Last 3 months</option>
            <option value={'Last6months'}>Last 6 months</option>
            <option value={'Last12months'}>Last 12 months</option>
            <option value={'hold'}>Hold</option>
            <option value={'active'}>Active</option>
          </select>
          <button type='button' onClick={handleFilter}>Search</button>
        </div>
        <div className="d-flex justify-content-end">
          <button className="btn btn-dark new" onClick={handleAddPeople}>
            + New
          </button>
          <button className="btn btn-dark new" id="share" onClick={handleCreate}>
            <CiShare1 /> Share with vendor
          </button>
        </div>
        {loading ? (
          <ShimmerTable />
        ) : (
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">S.N</th>
                <th scope="col">Name</th>
                <th scope="col">Company Name</th>
                <th scope="col">Phone Number</th>
                <th scope="col">Email</th>
                <th scope="col">Total Billing</th>
                <th scope="col">Client Status</th>
                <th scope="col">Pending Amount</th>
                <th scope="col">Client Cycle</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {vendors?.map((vendor, index) => (
                <tr className={index % 2 === 0 ? "bg-light" : "bg-secondary text-black"}>
                  <td><input type='checkbox' /></td>
                  <td>{index + 1}</td>
                  <td>{vendor.nameOfContact}</td>
                  <td>{vendor.orgName}</td>
                  <td>{vendor.phone}</td>
                  <td>{vendor.email}</td>
                  <td>{vendor.totalBilling}</td>
                  <td><span id={vendor.clientStatus}>{vendor.clientStatus}</span></td>
                  <td>{vendor.pendingAmount}</td>
                  <td>{vendor.clientCycle}</td>
                  <td>
                    <DropdownButton id="dropdown-item-button btn-dark" variant='dark' title={<BsThreeDots size={26} />}>
                      <Dropdown.Item as="button" onClick={() => navigate(`/customer/edit/${vendor._id}`)}>View/Edit Customer</Dropdown.Item>
                      <Dropdown.Item as="button" onClick={() => handleDelete(vendor._id)}>Delete</Dropdown.Item>
                    </DropdownButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}

export default PeopleList;
