import React, { useState, useEffect } from "react";
import floro from "../../../assets/images/flo.jpeg";
import "../../../assets/css/po.css";
import { useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { ToWords } from "to-words";

const tableStyle = {
  borderCollapse: "collapse",
  width: "97%",
  marginRight: "auto",
};

const thTdStyle = {
  border: "1px solid black",
  padding: "5px",
  textAlign: "left",
};

function PoDetails() {
  const poId = useParams();
  const userToken = localStorage.getItem("userToken");
  const [po, setPo] = useState({});
  const [customerDetails, setCustomer] = useState();
  const [creator, setCreator] = useState();
  const toWords = new ToWords();

  const loadInvoice = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_HOST}purchaseorder/${poId.poId}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setPo(response.data.purchaseOrder);
        setCreator(response.data.creator);
        setCustomer(response.data.customerDetails);
        console.log(response?.data?.purchaseOrder);

      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    loadInvoice();
  }, []);
  const convertToCroreLakhThousand = (number) => {
    const crore = Math.floor(number / 10000000);
    const lakh = Math.floor((number % 10000000) / 100000);
    const hundred = Math.floor((number % 100000) / 1000);
    const thousand = number % 1000;

    return {
      crore,
      lakh,
      hundred,
      thousand,
    };
  };

  return (
    <>
      <div className="fonts parent Floro-container">
        <div className="Floro-container-one">
          <div className="inner-ring">
            <h2 id="invoicetype">Purchase Order </h2>
            <div className="container-one top-po">
              <div className="container-one-left">
                <img src={floro} alt="" id="pologo" />
              </div>

              <div className="container-one-right personal">
                <h5>Original</h5>
                {creator?.orgName && <h4>{creator?.orgName}</h4>}
                {creator?.address && <h4> {creator?.address}</h4>}
                <h4>
                  {creator?.city}
                  {`, `}
                  {creator?.state}{" "}
                </h4>
                {creator?.gstNo && <h4>GSTIN: {creator?.gstNo} </h4>}
              </div>
            </div>
            <hr className="hr" />

            <div className="container-two">
              <div className="container-two-left">
                {po?.transactionType && (
                  <h6 style={{ marginLeft: "5px" }}>
                    <span id="fixwidth">Transaction Type</span>:{` `}{" "}
                    {po?.transactionType}
                  </h6>
                )}
                {po?.natureOfSupply && (
                  <h6 style={{ marginLeft: "5px" }}>
                    <span id="fixwidth">Nature of Supply </span>:{` `}
                    {po?.natureOfSupply}{" "}
                  </h6>
                )}
              </div>
              <div className="container-two-right"></div>
            </div>
            <div className="container-two">
              <div className="container-two-left">
                <h6 id="vendorDetails" style={{ marginLeft: "5px",fontSize:'15px !important'}}><strong>Vendor Details</strong></h6>
                <h6 style={{ marginLeft: "5px" }}>
                  <span id="fixwidth">Registered Name: </span>
                  {customerDetails?.orgName}
                </h6>
                {customerDetails?.address && (
                  <h6 style={{ marginLeft: "5px" }}>
                    <span id="fixwidth">Address</span>:{" "}
                    <span>: {customerDetails?.address}</span>{" "}
                  </h6>
                )}
                <br />
                {customerDetails?.gstNo && (
                  <h6 style={{ marginLeft: "5px" }}>
                    <span id="fixwidth">GSTIN</span>:{` `}{" "}
                    {customerDetails?.gstNo}{" "}
                  </h6>
                )}
                {customerDetails?.fullName && (
                  <h6 style={{ marginLeft: "5px" }}>
                    <span id="fixwidth">Attention to</span>:{` `}{" "}
                    {customerDetails?.fullName}{" "}
                  </h6>
                )}
                {customerDetails?.email && (
                  <h6 style={{ marginLeft: "5px" }}>
                    <span id="fixwidth">Email Id</span>
                    <span>:{customerDetails?.email}</span>{" "}
                  </h6>
                )}
                {customerDetails?.phone && (
                  <h6 style={{ marginLeft: "5px" }}>
                    <span id="fixwidth">Phone</span>:{` `}{" "}
                    {customerDetails?.phone}{" "}
                  </h6>
                )}

                <div className="bill-para">
                  <h6 style={{ marginLeft: "0px" }}>
                    <span id="fixwidth">Bill To:</span>:{` `}{" "}
                    {customerDetails?.orgName}{" "}
                  </h6>
                  <p>
                    {customerDetails?.address} <br />
                    GSTIN: {customerDetails?.gstNo}{" "}
                  </p>
                </div>
                <div>
                  <h6 id="fixwidth">Note to Supplier: </h6>
                  <p>{po?.noteToSupplier} </p>
                </div>
              </div>
              <div className="container-two-right">
                {po?.createdDate && (
                  <h6 style={{ marginLeft: "5px" }}>
                    {" "}
                    <span id="fixwidth">PO Date </span>:{` `}
                    {moment(po?.createdDate).format("DD-MM-YYYY")}
                  </h6>
                )}
                {po?.orderNumber && (
                  <h6 style={{ marginLeft: "5px" }}>
                    {" "}
                    <span id="fixwidth">PO Number</span>:{` `} {po?.orderNumber}
                  </h6>
                )}
                {po?.orderTerms && (
                  <>
                    <h6 style={{ marginLeft: "5px" }}>
                      {" "}
                      <span id="fixwidth">PO Terms</span>:{" "}
                      <p id="terms"> {po?.orderTerms}</p>
                    </h6>
                  </>
                )}
                {po?.quotationNo && (
                  <h6 style={{ marginLeft: "5px" }}>
                    {" "}
                    <span id="fixwidth">Quotation Number</span> :{` `}
                    {po?.quotationNo}
                  </h6>
                )}
                {po?.natureOfSupply && (
                  <h6 style={{ marginLeft: "5px" }}>
                    {" "}
                    <span id="fixwidth">Nature of Expense </span>:{` `}
                    {po?.natureOfSupply}
                  </h6>
                )}
                {po?.advance && (
                  <h6 style={{ marginLeft: "5px" }}>
                    {" "}
                    <span id="fixwidth">PAdvance</span>:{` `} {po?.advance}
                  </h6>
                )}
                {po?.orderCreatedBy && (
                  <h6 style={{ marginLeft: "5px" }}>
                    {" "}
                    <span id="fixwidth">PO Created By </span>:{` `}
                    {po?.orderCreatedBy}{" "}
                  </h6>
                )}
                {po?.orderApprovedBy && (
                  <h6 style={{ marginLeft: "5px" }}>
                    {" "}
                    <span id="fixwidth">PO Approved By </span>:{` `}
                    {po?.orderApprovedBy}{" "}
                  </h6>
                )}
                {po?.dueDate && (
                  <h6 style={{ marginLeft: "5px" }}>
                    {" "}
                    <span id="fixwidth">PO Expiry Date</span>:{` `}
                    {moment(po?.dueDate).format("DD-MM-YYYY")}{" "}
                  </h6>
                )}
              </div>
            </div>

            <div className="Table-container-five">
              <table
                style={{
                  borderCollapse: "collapse",
                  width: "97%",
                  marginLeft: "",
                  border: "none",
                }}
              >
                <colgroup>
                  <col style={{ width: "8%" }} />
                  <col style={{ width: "54%" }} />
                  <col style={{ width: "10%" }} />
                  <col style={{ width: "10%" }} />
                  <col style={{ width: "12.8%" }} />
                  <col style={{ width: "12%" }} />
                </colgroup>
                <thead id="head">
                  <tr>
                    <th style={{ color: "black", border: "1px solid black" }}>
                      S.N
                    </th>
                    <th style={{ color: "black", border: "1px solid black" }}>
                      Description of Goods
                    </th>
                    <th style={{ color: "black", border: "1px solid black" }}>
                      HSN
                    </th>
                    <th style={{ color: "black", border: "1px solid black" }}>
                      QTY
                    </th>
                    <th style={{ color: "black", border: "1px solid black" }}>
                      UOM
                    </th>
                    <th style={{ color: "black", border: "1px solid black" }}>
                      Unit Price
                    </th>
                    <th style={{ color: "black", border: "1px solid black" }}>
                      Total Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {po?.productDesc?.map((p, index) => (
                    <tr key={index}>
                      <td style={{ border: "1px solid black" }}>{index + 1}</td>
                      <td
                        style={{
                          fontSize: "14px",
                          fontFamily: "sans-serif",
                          border: "1px solid black",
                        }}
                      >
                        {p.name}
                      </td>
                      <td style={{ border: "1px solid black" }}>{p.hsn}</td>
                      <td style={{ border: "1px solid black" }}>{p.qty}</td>
                      <td style={{ border: "1px solid black" }}>{p.uom}</td>
                      <td style={{ border: "1px solid black" }}>{ parseInt(p.price)?.toFixed(0)}</td>
                      <td style={{ border: "1px solid black" }}>
                        {(parseInt(p.qty) * parseInt(p.price).toFixed(0))}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="last-container">
              <div className="last-container-left before-div"></div>
              <div
                className="last-container-right-po"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div className="Table-container-six tax">
                  <table
                    style={{ ...tableStyle, width: "99.2%" }}
                    className="purchasetable"
                  >
                    <tbody>
                      <tr>
                        <th style={thTdStyle}>Subtotal</th>
                        <td style={thTdStyle}>{po?.subTotal?.toFixed(0)}</td>
                      </tr>
                      {/* <tr>
                        <th style={thTdStyle}>Discount</th>
                        <td style={thTdStyle}>{po?.discount || 0}</td>
                      </tr> */}
                      {/* <tr>
                        <th style={thTdStyle}>Subtotal After Discount</th>
                        <td style={thTdStyle}>{po?.subAfterDiscount}</td>
                      </tr> 
                      <tr>
                        <th style={thTdStyle}>{po?.igst ? 'Tax Rate' : 'GST'}</th>
                        <td style={thTdStyle}>{'18%'}</td>
                      </tr>*/}
                      {po?.taxType==='igst' ? (
                        <tr>
                          <th style={{...thTdStyle,minWidth:'100px'}}>IGST 18%</th>
                          <td style={thTdStyle}>{po?.igst?.toFixed(0)}</td>
                        </tr>
                      ) : (
                        <>
                          <tr>
                            <th style={thTdStyle}>SGST 9%</th>
                            <td style={thTdStyle}>{po?.sgst?.toFixed(0)}</td>
                          </tr>
                          <tr>
                            <th style={thTdStyle}>CGST 9%</th>
                            <td style={thTdStyle}>{po?.cgst?.toFixed(0)}</td>
                          </tr>
                        </>
                      )}
                      <tr>
                        <th style={thTdStyle}>Total Tax</th>
                        <td style={thTdStyle}>{po?.totalTax?.toFixed(0)}</td>
                      </tr>
                      {/* <tr>
                        <th style={thTdStyle}>Shipping/Handling Charges</th>
                        <td style={thTdStyle}>{po?.shipOrHandCharge || 0}</td>
                      </tr> */}
                      <tr>
                        <th style={thTdStyle}>
                          <h6 style={{ fontWeight: 'bolder', fontSize: '18px !important' }}>TOTAL</h6>
                        </th>
                        <td style={thTdStyle}>
                          <h6 style={{ fontWeight: 'bolder', fontSize: '18px !important' }}>
                            {po?.totalINR?.toFixed(0)}
                          </h6>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="after-div">
              <h6>
                Total Amount in words:{" "}
                <strong>
                  {toWords.convert(po?.totalINR?.toFixed(0) || 0)} Rupees only
                </strong>
              </h6>
            </div>

            <h6 style={{ marginLeft: "5px", marginTop: "40px" }}>Note:</h6>
            <h6
              style={{
                color: "red",
                marginLeft: "10px",
                marginTop: "10px",
                fontSize: 11,
                fontFamily: "sans-serif",
              }}
            >
               For a quick solve on any queries in ledger statement & reco,
              payments & advices, please reach us at <br />
              info@florosense.com, accounts@florosense.com
            </h6>
            <p>
              This is a computer-generated document and it does not require a
              signature for validation{" "}
            </p>
          </div>
        </div>
      </div>

      <div className="parent Floro-container lastc">
        <div className="Floro-container-one mt-3">
          <h4 style={{ textAlign: "center", marginTop: "5px", fontSize: 14 }}>
            STANDARD TERMS AND CONDITIONS{" "}
          </h4>
          <p style={{ marginTop: "5px" }}>
            {" "}
            1. The work to be executed strictly as per the issued Purchase
            order. commercial approval is mandatory before the execution at
            site.
          </p>
          <p style={{ marginTop: "5px" }}>
            2. Acceptance of PO: Vendor is requested to provide confirmation on
            the PO to the respective Business Unit of the Company. Failing to
            revert on the PO within three days of the receipt of the same,
            shall be deemed to be the acceptance of the PO by the Vendor. Vendor
            agrees to be bound by and to comply with all these terms, which
            include any supplements to it, and all specifications and other
            documents referred to in this PO.
          </p>
          <p>
            {" "}
            3. Delivery of Goods/ services: The Vendor must deliver the goods/
            service to the delivery address by the delivery date in accordance
            with the PO. Time is of the essence for delivery. Without limitation
            to any other rights or remedies the Company may have, the Vendor
            will compensate the Company if it fails to deliver all the goods in
            accordance with the PO (which will include without limitation any
            and all consequential or indirect losses, loss of profits.). The
            Company may also either cancel the PO or extend the delivery dates
            at its sole discretion in case of a delay.
          </p>

          <p>
            {" "}
            4. Packaging: All goods must be packaged in the manner specified by
            the Company and shipped in the manner agreed with the Company. If
            Company does not specify the manner in which the goods must be
            packaged, the Vendor shall pack the goods so as to ensure sea
            /air/rail/roadworthy packaging to avoid any damage in transit.
          </p>

          <p>
            5. Inspection: The Company reserves the right to reject goods and
            services which are defective, not genuine or not in accordance with
            the agreed specifications. Company's payment for the goods shall not
            constitute its acceptance of the goods. Goods which are rejected may
            be returned to the Vendor at the Vendor's sole cost and expense.
            Payment, if any, made for any goods rejected hereunder shall be
            promptly refunded by the Vendor{" "}
          </p>

          <p>
            {" "}
            6. Payment Terms: The payment schedule is set out in this PO. Any
            advance paid shall be adjusted as per the payment schedule mentioned
            herein. In the event, the terms for adjustment of advance are not
            mentioned in the payment schedule, then the advances will be settled
            from the first invoice raised by the Vendor, and balance unadjusted
            amounts (if any) shall be settled from the subsequent bills.
          </p>

          <p>
            {" "}
            7. Taxes: TDS shall be deductible as per the relevant statutes of
            the Income Tax Act, 1961 and rules framed under from time to time.
            GST as applicable on the Goods/Services Provided, Including GST tax
            payable on a reverse charge basis. In case of any credit or other
            benefit is denied or delayed to FESPL due to any non-compliance by
            the supplier (such as failure to upload the details of the sale on
            GSTN portal, failure to pay GST to the Government) or due to
            non-furnishing or furnishing of incorrect or incomplete documents by
            the supplier, it would be reimbursed to FESPL. The loss including,
            but not limited to, the input tax credit loss, interest and penalty.
            The Vendor is required to check the applicability of E-Waybill
            before dispatching the Goods and should dispatch the Goods with
            proper EWaybill. The Company shall not be responsible for any
            consequences owing to nonissuance of proper E-waybill. The Company
            shall not accept/receive the Goods without E-waybill, wherever
            applicable. Customs duty as applicable shall be payable at the time
            of clearance of the goods at the port of import and Vendor shall
            fulfil all the obligations under the applicable laws.
          </p>

          <p>
            {" "}
            8. Invoice: The Vendor shall raise a valid tax invoice carrying all
            the declarations as prescribed under the law on GST and conform to
            the requirements of the applicable tax/GST laws as provided below:{" "}
            <br />
            (i) Name, address, GSTIN and PAN No. of the Vendor; <br />
            (ii) Invoice number unique for a financial year; <br />
            (iii) Date of its issue; <br />
            (iv) PO number; <br />
            (v) Name, address and GSTIN of the Company; <br />
            (vi) HSN code of goods or Accounting Code of services; <br />
            (vii) Description of goods or services; <br />
            (viii) Quantity and unit or Unique Quantity Code thereof; <br />
            (ix) Total value of supply of goods or services or both; <br />
            (x)Taxable value of supply of goods or services or both taking into
            account discount or abatement, if any; (xi) Rate of tax; (xii)
            Amount of tax charged in respect of taxable goods or services <br />
            (xiii) Place of supply along with the name of State, in case of a
            supply in the course of inter-State trade or commerce; with correct
            'bill to', 'ship to' location; <br />
            (xiv) Address of delivery where the same is different from the place
            of supply; <br />
            (xv) Whether the tax is payable on the reverse charge basis; and{" "}
            <br />
            (xvi) Signature or digital signature of the Vendor or its authorized
            representative{" "}
          </p>

          <p>
            {" "}
            9. Product Warranties: Vendor warrants to Company that the goods
            are: <br />
            (i) genuine of merchantable quality and as per applicable BIS/ISI
            standards; <br />
            (ii) fit for the purposes intended; <br />
            (iii) in strict compliance with the specifications contained in this
            PO; <br />
            (iv) free from any liens or encumbrances on title whatsoever; and{" "}
            <br />
            (v) compliant with all applicable laws.{" "}
          </p>

          <p>
            {" "}
            10. Service Warranties: Vendor shall perform all services: <br />
            (i) in accordance with all specifications mentioned in this PO; and{" "}
            <br />
            (iii) using only personnel with the skills, training, expertise, and
            qualifications necessary to carry out the services{" "}
          </p>
          <p>
            {" "}
            11. Intellectual Property Warranty. The goods and services provided
            pursuant to this PO will not infringe any Intellectual Property
            Rights of any <br />
            person. Further, the Vendor shall not use any of the intellectual
            property rights of the Company, without the prior written consent of
            the Company{" "}
          </p>
        </div>
      </div>

      <div className="parent Floro-container lastc">
        <div className="Floro-container-one mt-3">
          <p style={{ marginTop: "0px" }}>
            12. Manufacturer Warranties: Vendor shall assign to Company all
            manufacturer's warranties for goods not manufactured by the Vendor
            and shall tak 2 e all necessary steps as required by such thirdparty
            manufacturers to effect the assignment of such warranties to
            Company. All requisite manufacturer's guarantees and warranties
            along with service or maintenance contracts shall be handed over to
            the Company at the time of delivery of goods/performance of service{" "}
          </p>

          <p style={{ marginTop: "5px" }}>
            {" "}
            13. Compliance with Laws: The Vendor warrants that it shall comply
            with all applicable central, state, local laws, rules and
            regulations in relation to the supply of goods and/or performance of
            services agreed under this PO, including but not limited to
            compliance with all statutory contributions under various labour
            laws.
          </p>
          <p style={{ marginTop: "5px" }}>
            14. Indemnity: Vendor shall indemnify, defend and hold harmless the
            Company from and against any claims arising out of the Vendor's
            breach of any of its terms and conditions under this PO{" "}
          </p>
          <p style={{ marginTop: "5px" }}>
            {" "}
            15. Insurance: Vendor shall avail and keep in force comprehensive
            commercial general liability insurance including product liability
            and transit insurance covering the goods supplied by the Vendor. The
            Vendor shall maintain a Workmen's Compensation Insurance Policy in
            accordance with the Workmen's Compensation Act, 1923 or any other
            applicable statutory requirements from time to time covering all its
            employees/representatives (including those engaged by the third
            parties) in the performance of the PO.
          </p>

          <p style={{ marginTop: "5px" }}>
            {" "}
            16. Confidentiality: The Vendor shall safeguard and keep
            confidential any and all information relating to the Company which
            is obtained by it or provided to it by the Company in connection
            with this PO and shall use such information only for the purposes of
            carrying out its obligations under this PO
          </p>

          <p style={{ marginTop: "5px" }}>
            17. Termination: Company may terminate all or any part of this PO
            without specifying any reason at any time by written notice to
            Vendor. Upon such termination, the liability of the Company will be
            limited to make payments for the goods/ services, duly accepted by
            it till that date. Further, Vendor shall immediately stop work and
            terminate all orders and subcontracts to the extent that they relate
            to the terminated work. No charges shall accrue to the Company for
            cancellation of this PO.
          </p>
          <p style={{ marginTop: "5px" }}>
            {" "}
            18. Assignment and Sub-contracting: The Vendor shall not assign this
            PO or appoint any sub-contractor without the prior written consent
            of the Company. In case of appointment of sub-contractors, the
            Vendor shall remain responsible for all acts and omissions of such
            sub-contractors.
          </p>

          <p style={{ marginTop: "5px" }}>
            {" "}
            19. Arbitration: Any issues or differences arising out of, from, or
            relating to anything contained in this PO. The Arbitration
            proceedings shall be conducted by a sole arbitrator to be appointed
            mutually by the parties. Such arbitration shall be governed by the
            provisions of the Indian Arbitration and Conciliation Act, 1996 or
            any statutory modification or re-enactment thereof for the time
            being in force. The venue of the arbitration shall be Bangalore.
          </p>

          <p style={{ marginTop: "5px" }}>
            20. Law/ Jurisdiction: The PO shall be governed by the laws of India
            and any and all disputes shall be subject to Courts of competent
            jurisdiction in Bangalore. 21. Vendor Completion Certificate: Vendor
            agrees and affirms that the Vendor shall provide a completion
            certificate in writing to the Company (in the manner as prescribed
            by the Company) stating that Vendor has completed the entire scope
            of Services with due diligence and that there is no defect in the
            Services provided by the Vendor. In the event the Company suffers
            any third party claims due to the defect in the Services, Vendor
            agrees to indemnify and hold harmless the Company with respect to
            any of the above-mentioned claims.
          </p>

          <p style={{ marginTop: "5px" }}>
            {" "}
            22. Vendor Liability: Vendor agrees and acknowledges that it shall
            solely be liable for all its acts / omissions as well as for all
            acts / omissions of its personnel while providing the services under
            this PO including any injury caused to / by the Vendor and / or its
            personnel during the course of provision of the services herein.
            Vendor further affirms that all personnel employed by the Vendor in
            connection with this PO shall be the employees or contractors of the
            Vendor. Vendor agrees to make all payments, statutory or otherwise,
            to its personnel in a timely manner and provide documentary evidence
            in this respect to the Company if requested by the Company. Vendor
            agrees that Company shall in no manner be responsible in any of the
            above-mentioned instances and Vendor shall at all times ke3ep the
            Company indemnified and harmless in connection with the abov
          </p>
        </div>
      </div>
    </>
  );
}

export default PoDetails;
