import axios from "axios";
import Swal from "sweetalert2";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LogoImg from "../../assets/images/flo.jpeg";
import Form from "react-bootstrap/Form";


const SignUp = (prop) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [validated, setValidated] = useState(false);

  const createAccount = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
     
      let data = JSON.stringify({
        fullName: formData.fullName,
        email: formData.email,
        password: formData.password,
        phone: formData.phone,
        orgName:formData.orgName
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_HOST}registration`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: data
      };

      axios
        .request(config)
        .then((response) => {
          console.log(response.data.status)
          if (response.data.status) {
            localStorage.setItem("userToken", response.data.userToken);
            navigate("/");
            Swal.fire({
              icon: "success",
              title: "Account Created Successfully",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "warning",
            title: error.response.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
        });
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });

  };

  const handleLogin = () => {
    navigate("/");
  }

  return (
    <div className="vh-100 d-flex justify-content-center align-items-center">

      <div className="login-box create">
        <div className="wrapper">
          <div id="left">
          <div className="login-heading">
            {/* <img src={Logo} alt="logo" className="mx-auto d-block" /> */}
            <h1>Create new account!</h1>
            <p>to access billing system!</p>
            <label id="strong">Your Details</label>
          </div>
          </div>
          <div id="right">
            <div className="sign-in signup">
             
              <Form
                noValidate
                validated={validated}
                className="signupHomepage"
                onSubmit={createAccount}
              >

                
                <Form.Group controlId="validationCustom03">
                  <span>
                
                    <Form.Control
                      placeholder="Enter your name"
                      name="fullName"
                      required
                      value={formData.fullName}
                      onChange={handleChange}
                      type="string"
                      className="rg-form"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please fill your Name
                    </Form.Control.Feedback>
                  </span>
                </Form.Group>
                <Form.Group controlId="validationCustom03">
                  <span>
                
                    <Form.Control
                      placeholder="Enter your organisation name"
                      name="orgName"
                      required
                      value={formData.orgName}
                      onChange={handleChange}
                      type="string"
                      className="rg-form"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please fill your Name
                    </Form.Control.Feedback>
                  </span>
                </Form.Group>
                <Form.Group controlId="validationCustom03">
                  <span>
                   
                    <Form.Control
                      placeholder="Enter email address"
                      name="email"
                      required
                      value={formData.email}
                      onChange={handleChange}
                      type="email"
                      className="rg-form"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please fill your Email
                    </Form.Control.Feedback>
                  </span>
                </Form.Group>
                <Form.Group controlId="validationCustom03">
                  <span>
                   
                    <Form.Control
                      placeholder="Enter mobile number"
                      name="phone"
                      required
                      value={formData.phone}
                      onChange={handleChange}
                      type="string"
                      className="rg-form"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please fill your phone
                    </Form.Control.Feedback>
                  </span>
                </Form.Group>

                <Form.Group controlId="validationCustom03">
                  <span>
                 
                    <Form.Control
                     placeholder="Choose a strong password"
                      name="password"
                      required
                      value={formData.password}
                      onChange={handleChange}
                      type="Choose a strong"
                      className="rg-form"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please fill your password
                    </Form.Control.Feedback>
                  </span>
                </Form.Group>
                <br />
                <div className="d-flex">
                  <button type="submit" className="btn btn-primary">Create Account</button>
                
                </div>
                <button type='button' className="btn btn-secondary " onClick={handleLogin}> Already have an account?
                  </button>

              </Form>


            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default SignUp;
