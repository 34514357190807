import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import Logo from "../../assets/images/flo.jpeg";

const Login = (prop) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const userToken = localStorage.getItem("userToken");

  useEffect(() => {

    if (userToken) {
      navigate('/dashboard');
    }
  }, [])


  const createAccount = async (e) => {
    e.preventDefault();
    let data = JSON.stringify({
      email: formData.email,
      password: formData.password,
    });
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_HOST}login`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data
    };

    axios
      .request(config)
      .then((response) => {
        
        if (response.data.status) {
          localStorage.setItem("userToken", response.data.userToken);
          navigate("/dashboard");
          Swal.fire({
            icon: "success",
            title: "Logged In Successfully",
            showConfirmButton: false,
            timer: 1500,
          });
        }

      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          icon: "warning",
          title: error.response.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });

  };

  return (
    <>
      <div className="vh-100 d-flex justify-content-center align-items-center">
        <div className="login-box">
          <div className="login-heading">
            {/* <img src={Logo} alt="logo" className="mx-auto d-block" /> */}
            <h1>Login now</h1>
            <p>to access billing system!</p>
          </div>
          <form>
            <div className="textinput">
              <label>Customer ID</label>
              
              <input name="email" onChange={handleChange} placeholder="Enter Email Address" value={formData.email} type="email" />
            </div>

            <div className="textinput">
             
              <input name="password" onChange={handleChange} placeholder="Enter Password" value={formData.password} type="password" />
            </div>
            <div className="forgotPassword">
              <a href="#">Forgot Password?</a>
            </div>

            <div className="inputBtn">
              <input onClick={createAccount} type="submit" value="Login" />
            </div>

            <div className="newAccount">
              <a href="/signup" >Create new account?</a>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
