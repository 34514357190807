import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Tabs, Tab } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { useNavigate, useParams } from 'react-router-dom';

const EditCustomer = () => {
  const token = localStorage.getItem("userToken");
  const { id } = useParams();
  const [key, setKey] = useState('personal');
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [fileInputs, setFileInputs] = useState({
    pan: '',
    gst: '',
    cheque: '',
    msme: '',
    logo: ''
  });

  const handleFileInputChange = (event) => {
    const { name, files } = event.target;
    setFileInputs({
      ...fileInputs,
      [name]: files[0],
    });
  };


  useEffect(() => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_HOST}customer/${id}`,
      headers: {
        'Authorization': `Bearer ${token}`
      }
    };

    axios.request(config)
      .then((response) => {
        setFormData(response?.data)

      })
      .catch((error) => {
        console.log(error);
      });
  }, [])





  const uploadFiles = async (typ) => {

    let fileData = new FormData();
    switch (typ) {
      case "pan":
        fileData.append('mediaKey', fileInputs.pan); // Use correct keys for fileInputs
        break;

      case "gst":
        fileData.append('mediaKey', fileInputs.gst); // Use correct keys for fileInputs
        break;

      case "cheque":
        fileData.append('mediaKey', fileInputs.cheque); // Use correct keys for fileInputs
        break;

      case "msme":
        fileData.append('mediaKey', fileInputs.msme); // Use correct keys for fileInputs
        break;

      case "logo":
        fileData.append('mediaKey', fileInputs.logo); // Use correct keys for fileInputs
        break;
    }


    try {
      let response = await axios.post(`${process.env.REACT_APP_HOST}media-upload`, fileData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
      });
      switch (typ) {
        case "pan":
          setFormData({ ...formData, panURL: response.data.mediaKey }); // Use correct keys for fileInputs
          break;

        case "gst":
          setFormData({ ...formData, gstURL: response.data.mediaKey }); // Use correct keys for fileInputs
          break;
        case "msme":
          setFormData({ ...formData, msmeURL: response.data.mediaKey }); // Use correct keys for fileInputs
          break;


        case "cheque":
          setFormData({ ...formData, chequeURL: response.data.mediaKey }); // Use correct keys for fileInputs
          break;

        case "logo":
          setFormData({ ...formData, logoURL: response.data.mediaKey }); // Use correct keys for fileInputs
          break;
      }

    } catch (error) {
      console.error(error);
    }


  }

  const handleOnChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });

  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const apiUrl = `${process.env.REACT_APP_HOST}customer/${id}`;

    try {
      const response = await axios.patch(apiUrl, formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.status) {
        await Swal.fire({
          icon: "success",
          title: "Customer Edited Successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        window.location.reload();
      }
    } catch (error) {
      Swal.fire({
        icon: "warning",
        title: error?.response?.data?.message,
        showConfirmButton: false,
        timer: 1500,
      });

    }
  };

  return (
    <div className="container-right">
      <h4>View / Edit Customer</h4>
      <form onSubmit={handleSubmit}>
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab eventKey="personal" title="Contact Information">
            <div className='biiling-inshights settings'>
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td>Organization Name</td>
                    <td>
                      <input
                        onChange={handleOnChange} value={formData?.orgName} type="text" className="form-control" name="orgName" />
                    </td>
                  </tr>

                  <tr>
                    <td>Email Address</td>
                    <td>
                      <input
                        onChange={handleOnChange} value={formData?.email} type="email" className="form-control" name="email" />
                    </td>
                  </tr>


                  <tr>
                    <td>Phone Number</td>
                    <td>
                      <input
                        onChange={handleOnChange} value={formData?.phone} type="text" className="form-control" name="phone" />
                    </td>
                  </tr>

                  <tr>
                    <td>Address</td>
                    <td>
                      <input
                        onChange={handleOnChange} value={formData?.address} type="text" className="form-control" name="address" />
                    </td>
                  </tr>
                  <tr>
                    <td>City</td>
                    <td>
                      <input
                        onChange={handleOnChange} value={formData?.city} type="text" className="form-control" name="city" />
                    </td>
                  </tr>
                  <tr>
                    <td>State</td>
                    <td>
                      <input
                        onChange={handleOnChange} value={formData?.state} type="text" className="form-control" name="state" />
                    </td>
                  </tr>
                  <tr>
                    <td>Country</td>
                    <td>
                      <input
                        onChange={handleOnChange} value={formData?.country} type="text" className="form-control" name="country" />
                    </td>
                  </tr>
                  <tr>
                    <td>ZIP Code</td>
                    <td>
                      <input
                        onChange={handleOnChange} value={formData?.zipCode} type="number" className="form-control" name="zipCode" />
                    </td>
                  </tr>
                  <tr>
                    <td>Telephone</td>
                    <td>
                      <input
                        onChange={handleOnChange} value={formData?.telephone} type="text" className="form-control" name="telephone" />
                    </td>
                  </tr>
     

                  <tr>
                    <td>Name Of Contact</td>
                    <td>
                      <input
                        onChange={handleOnChange} value={formData?.nameOfContact} type="text" className="form-control" name="nameOfContact" />
                    </td>
                  </tr>
                  <tr>
                    <td>Phone of Contact</td>
                    <td>
                      <input
                        onChange={handleOnChange} value={formData?.phoneOfContact} type="text" className="form-control" name="phoneOfContact" />
                    </td>
                  </tr>
                  <tr>
                    <td>Email of Contact</td>
                    <td>
                      <input
                        onChange={handleOnChange} value={formData?.emailOfContact} type="text" className="form-control" name="emailOfContact" />
                    </td>
                  </tr>



                </tbody>

              </table>
            </div>

          </Tab>
          <Tab eventKey="financial" title="Financial Information">
            <div className='biiling-inshights settings'>
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td>Currency</td>
                    <td>
                      <input
                        onChange={handleOnChange} value={formData?.currency} type="text" className="form-control" name="currency" />
                    </td>
                  </tr>

                  <tr>
                    <td>GST No.</td>
                    <td>
                      <input
                        onChange={handleOnChange} value={formData?.gstNo} type="text" className="form-control" name="gstNo" />
                    </td>
                  </tr>
                  <tr>
                    <td>TAN</td>
                    <td>
                      <input
                        onChange={handleOnChange} value={formData?.tan} type="text" className="form-control" name="tan" />
                    </td>
                  </tr>
                  <tr>
                    <td>PAN</td>
                    <td>
                      <input
                        onChange={handleOnChange} value={formData?.pan} type="text" className="form-control" name="pan" />
                    </td>
                  </tr>

                </tbody>

              </table>
            </div>

          </Tab>
          <Tab eventKey="banking" title="Banking Details">
            <div className='biiling-inshights settings'>
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td>Name of Bank</td>
                    <td>
                      <input
                        onChange={handleOnChange} value={formData?.nameOfBank} type="text" className="form-control" name="nameOfBank" />
                    </td>
                  </tr>

                  <tr>
                    <td>A/C Holder Name</td>
                    <td>
                      <input
                        onChange={handleOnChange} value={formData?.acHolderName} type="text" className="form-control" name="acHolderName" />
                    </td>
                  </tr>
                  <tr>
                    <td>Branch Name</td>
                    <td>
                      <input
                        onChange={handleOnChange} value={formData?.bankBranch} type="text" className="form-control" name="bankBranch" />
                    </td>
                  </tr>
                  <tr>
                    <td>Address of Branch</td>
                    <td>
                      <input
                        onChange={handleOnChange} value={formData?.addressBranch} type="text" className="form-control" name="addressBranch" />
                    </td>
                  </tr>

                  <tr>
                    <td>Branch Code</td>
                    <td>
                      <input
                        onChange={handleOnChange} value={formData?.codeNoBankOrBranch} type="text" className="form-control" name="codeNoBankOrBranch" />
                    </td>
                  </tr>
                  <tr>
                    <td>IFSC</td>
                    <td>
                      <input
                        onChange={handleOnChange} value={formData?.ifsc} type="text" className="form-control" name="ifsc" />
                    </td>
                  </tr>
                  <tr>
                    <td>Account Type</td>
                    <td>
                      <input
                        onChange={handleOnChange} value={formData?.accountType} type="text" className="form-control" name="accountType" />
                    </td>
                  </tr>
                  <tr>
                    <td>Swift Code</td>
                    <td>
                      <input
                        onChange={handleOnChange} value={formData?.swiftCode} type="text" className="form-control" name="swiftCode" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </Tab>

          <Tab eventKey="orgd" title="Organization Details">
            <div className='biiling-inshights settings'>
              <table className="table table-bordered">
                <tbody>
                <tr>
                    <td>Pending Amount</td>
                    <td>
                      <input
                        onChange={handleOnChange} value={formData?.pendingAmount} type="text" className="form-control" name="pendingAmount" />
                    </td>
                  </tr>
                <tr>
  <td>Company Status</td>
  <td>
    <select 
      onChange={handleOnChange} 
      value={formData?.clientStatus} 
      className="form-control" 
      name="clientStatus"
    >
      <option value="">Select status</option>
      <option value="active">Active</option>
      <option value="hold">Hold</option>
      <option value="rejected">Rejected</option>
      <option value="completed">Completed</option>
     
    </select>
  </td>
</tr>
                  <tr>
                    <td>Vendor Type</td>
                    <td>
                      <select
                        onChange={handleOnChange} value={formData?.vendorType} type="text" className="form-control" name="vendorType">
                        <option value={'Manufacturer'}>Manufacturer</option>
                        <option value={'First Stage Dealer'}>First Stage Dealer</option>
                        <option value={'Second Stage Dealer'}>Second Stage Dealer</option>
                        <option value={'Importer'}>Importer</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>Development Act</td>
                    <td>
                      <select
                        onChange={handleOnChange} value={formData?.devlopmentAct} type="text" className="form-control" name="devlopmentAct">
                        <option value={'yes'}>yes</option>
                        <option value={'no'}>no</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>Organization Size</td>
                    <td>
                      <select
                        onChange={handleOnChange} value={formData?.orgSize} type="text" className="form-control" name="orgSize">
                        <option value={'Micro'}>Micro</option>
                        <option value={'Small'}>Small</option>
                        <option value={'Medium'}>Medium</option>
                        <option value={'Other'}>Other</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>Ownership Type</td>
                    <td>
                      <select
                        onChange={handleOnChange} value={formData?.ownerType} type="text" className="form-control" name="ownerType">
                        <option value={'Proprietary'}>Proprietary</option>
                        <option value={'Private Limited Company'}>Private Limited Company</option>
                        <option value={'Partnership'}>Partnership</option>
                        <option value={'Public Limited Company'}>Public Limited Company</option>
                      </select>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Tab>

          <Tab eventKey="files" title="Documents Upload">
            <div className='biiling-inshights settings'>
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td>PAN</td>
                    <td>
                      <input onChange={handleFileInputChange} type="file" className="form-control" name="pan" />
                      <button type='button' onClick={() => uploadFiles("pan")}>{formData.panURL ? "Uploaded" : "Upload"}</button>
                    </td>
                  </tr>
                  <tr>
                    <td>GST</td>
                    <td>
                      <input onChange={handleFileInputChange} type="file" className="form-control" name="gst" />
                      <button type='button' onClick={() => uploadFiles("gst")}>{formData.gstURL ? "Uploaded" : "Upload"}</button>
                    </td>
                  </tr>
                  <tr>
                    <td>Canceled Cheque</td>
                    <td>
                      <input onChange={handleFileInputChange} type="file" className="form-control" name="cheque" />
                      <button type='button' onClick={() => uploadFiles("cheque")}>{formData.chequeURL ? "Uploaded" : "Upload"}</button>
                    </td>
                  </tr>
                  <tr>
                    <td>MSME Cerificate</td>
                    <td>
                      <input onChange={handleFileInputChange} type="file" className="form-control" name="msme" />
                      <button type='button' onClick={() => uploadFiles("msme")}>{formData.msmeURL ? "Uploaded" : "Upload"}</button>
                    </td>
                  </tr>
                  {/* <tr>
                    <td>Company Logo</td>
                    <td>
                      <input onChange={handleFileInputChange} type="file" className="form-control" name="logo" />
                      <button type='button' onClick={() => uploadFiles("logo")}>{formData.logoURL ? "Uploaded" : "Upload"}</button>
                    </td>
                  </tr> */}
                  {/* <tr>
                    <td>Logo Name</td>
                    <td>
                      <input
                        onChange={handleOnChange} value={formData?.logoType} type="text" className="form-control" name="logoType" />
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </Tab>
        </Tabs>


        <div className='text-center m-5 btn'>


          <button className='btn btn-primary' type='submit' >Save</button>
        </div>







      </form>

    </div>
  );
};

export default EditCustomer;
